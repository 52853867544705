/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Chip, Fab } from '@mui/material';
import {
    FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus-icon.svg';
import { AppRoute } from '../../../constants/routes';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';
import { ButtonVariant, OptionCheckboxGroupField, OrderOptions } from '../../../types/general';
import { Permissions } from '../../../types/permissions';
import {
    RentalWorkflowStateEnum,
    SellWorkflowStateEnum, Workflow, WorkflowsFilterParams, WorkflowType,
} from '../../../types/workflows';
import { preparePageTitle } from '../../../utils/route';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import Button from '../../elements/Button';
import HasPermission from '../../elements/HasPermission';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { WorkflowCard } from '../../elements/workflows/WorkflowCard';
import { WorkflowsFiltersForm } from '../../elements/workflows/WorkflowsFiltersForm';
import { Drawer } from '../../elements/Drawer';
import { ReactComponent as FiltersIcon } from '../../../assets/images/filters.svg';
import { formatToLocalDate } from '../../../utils/date';
import { ReactComponent as RemoveIcon } from '../../../assets/images/closeBtn.svg';
import { objectToParams, paramsToObject } from '../../../utils/misc';
import { Member } from '../../../types/members';

type OwnProps = TranslationContext & OrganizationsContext & WorkflowContext;

const defaultFilters: WorkflowsFilterParams = {
    _cursor: '',
    _limit: '',
    _sort: '',
    email: '',
    externalId: '',
    from: '',
    to: '',
    transactionType: undefined,
    transactionStatus: undefined,
    organizationMemberIds: [],
};

const initialFilters: WorkflowsFilterParams = {
    ...defaultFilters,
    _sort: OrderOptions.DESCENDING,
    transactionType: WorkflowType.SELL,
};

const OrganizationWorkflowsScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getOrganizationWorkflows,
        getOrganizationMembers,
        organizationSelected,
    } = props;

    const [searchParams, setSearchParams] = useSearchParams();

    const canManage = useUserHasPermission([Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, Permissions.MANAGE_ALL_ORGANIZATION_TRANSACTIONS]);
    const navigate = useNavigate();

    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [filters, setFilters] = useState<WorkflowsFilterParams>(initialFilters);
    const [organizationMembersToTranslateChips, setOrganizationMembersToTranslateChips] = useState<Member[]>([]);
    const [isLoadingMembers, setIsLoadingMembers] = useState(true);
    const [memberOptions, setMemberOptions] = useState<OptionCheckboxGroupField[]>([]);

    useEffect(() => {
        document.title = preparePageTitle(t('workflows.list.title'));
        loadMembers();

        const filtersFromParams: Partial<WorkflowsFilterParams> = {
            ...defaultFilters,
            ...paramsToObject(searchParams, defaultFilters),
        };
        // check if the sort option is valid
        if (!Object.values(OrderOptions).includes(filtersFromParams._sort as OrderOptions)) {
            filtersFromParams._sort = initialFilters._sort;
        }

        // check if transactionType is valid
        if (!Object.values(WorkflowType).includes(filtersFromParams.transactionType as WorkflowType)) {
            filtersFromParams.transactionType = initialFilters.transactionType;
        }

        // check if transactionStatus is valid
        if (!Object.values(SellWorkflowStateEnum).includes(filtersFromParams.transactionStatus as SellWorkflowStateEnum) && !Object.values(RentalWorkflowStateEnum).includes(filtersFromParams.transactionStatus as RentalWorkflowStateEnum)) {
            filtersFromParams.transactionStatus = initialFilters.transactionStatus;
        }
        // check if member uuid is valid

        setFilters((prev) => ({ ...prev, ...filtersFromParams }));
        getWorkflowsList(filtersFromParams);
    }, []);

    useEffect(() => {
        const { _cursor, ...filtersWithoutCursor } = filters;

        setSearchParams(objectToParams(filtersWithoutCursor));
    }, [filters]);

    /**
     * Get organization members
     *
     * @remarks
     * Get organization members on the first render
     */
    const loadMembers = async () => {
        if (!organizationSelected?.organization?.id) return;
        setIsLoadingMembers(true);
        const [membersData] = await getOrganizationMembers(organizationSelected.organization.id);
        if (membersData) {
            const membersTransformedOptions = membersData.map((option) => (
                {
                    id: option.userId,
                    value: false,
                    label: `${option.firstName} ${option.lastName}`,

                }));
            setMemberOptions(membersTransformedOptions);
            setOrganizationMembersToTranslateChips(membersData);
        }
        setIsLoadingMembers(false);
    };

    /**
     * Filter organization members
     *
     * @remarks
     * Filters members using search word
     */
    const filterOrganizationMembers = async (search: string = '') => {
        if (!organizationSelected?.organization?.id) return;
        setIsLoadingMembers(true);

        const [membersData] = await getOrganizationMembers(organizationSelected.organization.id, search);
        if (membersData) {
            const membersTransformedOptions = membersData.map((option) => (
                {
                    id: option.userId,
                    value: false,
                    label: `${option.firstName} ${option.lastName}`,
                }));
            setMemberOptions(membersTransformedOptions);
        }
        setIsLoadingMembers(false);
    };

    const appliedFilters: string[] = useMemo(() => {
        return Object.keys(filters)
            .filter((key) => !key.startsWith('_')
                && filters[key as keyof WorkflowsFilterParams] !== ''
                && filters[key as keyof WorkflowsFilterParams]?.length);
    }, [filters]);

    const getWorkflowsList = async (submittedFilters: WorkflowsFilterParams) => {
        if (!organizationSelected?.organization?.id) return;

        setIsLoading(true);

        const { _cursor, ...appliedFiltersWithoutCursor } = submittedFilters;
        const { _cursor: _c, ...existingFiltersWithoutCursor } = filters;

        const filtersToApply = isEqual(appliedFiltersWithoutCursor, existingFiltersWithoutCursor) ? submittedFilters : { ...submittedFilters, _cursor: '' };

        const [workflowsData] = await getOrganizationWorkflows(organizationSelected.organization.id, filtersToApply);

        if (workflowsData) {
            setWorkflows((prev) => (filtersToApply._cursor ? [...prev, ...workflowsData.results] : [...workflowsData.results]));
            setFilters({
                ...filtersToApply,
                _cursor: workflowsData.cursor,
            });
        }

        setIsLoading(false);
    };

    const applyFilters = (filtersToApply: WorkflowsFilterParams) => {
        setIsLoading(true);
        setWorkflows([]);
        setIsOpenFilters(false);
        getWorkflowsList({ ...filtersToApply, _cursor: '' });
    };

    const getAppliedFilterDisplayValue = (key: keyof WorkflowsFilterParams, value?: string) => {
        switch (key) {
            case 'transactionType':
            case 'transactionStatus':
                return t(`workflows.list.filters.${key}Options.${filters[key]}`);
            case 'organizationMemberIds': {
                const member = organizationMembersToTranslateChips.find((item) => item.userId === value);
                return member ? `${member.firstName} ${member.lastName}` : '';
            }
            case 'from':
            case 'to':
                return formatToLocalDate(filters[key]);
            default:
                return filters[key];
        }
    };

    const removeFilter = (keyToRemove: keyof WorkflowsFilterParams) => {
        const newFilters: WorkflowsFilterParams = {
            ...filters,
            [keyToRemove]: defaultFilters[keyToRemove],
        };
        setFilters(newFilters);
        getWorkflowsList(newFilters);
    };

    const removeOrganizationMemberIdsFilter = (idToRemove: string) => {
        const newFilters: WorkflowsFilterParams = {
            ...filters,
            organizationMemberIds: filters.organizationMemberIds?.filter((id) => id !== idToRemove) ?? [],
        };
        setFilters(newFilters);
        getWorkflowsList(newFilters);
    };

    const cleanAllFilters = () => {
        setIsOpenFilters(false);
        setFilters(defaultFilters);
        getWorkflowsList(defaultFilters);
    };

    return (
        <HasPermission permissions={[Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, Permissions.VIEW_ALL_ORGANIZATION_TRANSACTIONS, Permissions.MANAGE_ALL_ORGANIZATION_TRANSACTIONS, Permissions.REVIEW_ALL_ORGANIZATION_TRANSACTIONS, Permissions.REVIEW_ALL_ORGANIZATION_TRANSACTIONS_DOCUMENTS]}>
            <DefaultLayout>
                <div className="organization-workflows-screen" data-testid="organization-workflows-screen">
                    <div className="organization-workflows-screen__header">
                        <h1>{t('workflows.list.title')}</h1>
                        {canManage && (
                            <Button
                                variant={ButtonVariant.Curved}
                                extraClasses="large-add-btn shorter-btn"
                                testId="large-add-btn"
                                onClick={() => navigate(AppRoute.CreateWorkflowSelectType)}
                            >
                                {t('workflows.list.createBtn')}
                            </Button>
                        )}
                        <Button
                            extraClasses="circle-btn filters-trigger-btn"
                            onClick={() => setIsOpenFilters(true)}
                            testId="mobile-filters-trigger-btn"
                        >
                            <FiltersIcon />
                        </Button>
                    </div>
                    <div className="organization-workflows-screen__applied-filters" data-testid="applied-filters">
                        <div>
                            {appliedFilters.length > 0 && (
                                <>
                                    <div className="organization-workflows-screen__applied-filters__header" data-testid="applied-filters-header">
                                        {`${appliedFilters.length} ${t(`workflows.list.filters.${appliedFilters.length === 1 ? 'filterApplied' : 'filtersApplied'}`)}:`}
                                        <Button
                                            onClick={cleanAllFilters}
                                            testId="large-clean-btn"
                                        >
                                            {t('workflows.list.filters.cleanAll')}
                                        </Button>
                                    </div>
                                    <div className="organization-workflows-screen__applied-filters__list" data-testid="applied-filters-list">
                                        {appliedFilters.map((appliedFilter) => {
                                            if (appliedFilter === 'organizationMemberIds') {
                                                return filters.organizationMemberIds?.map((id) => (
                                                    <Chip
                                                        key={`${appliedFilter}-${id}`}
                                                        className="organization-workflows-screen__applied-filters__list__item"
                                                        label={`${t(`workflows.list.filters.${appliedFilter}`)}: ${getAppliedFilterDisplayValue(appliedFilter as keyof WorkflowsFilterParams, id)}`}
                                                        onDelete={() => removeOrganizationMemberIdsFilter(id)}
                                                        deleteIcon={<RemoveIcon />}
                                                    />
                                                ));
                                            }
                                            return (
                                                <Chip
                                                    key={appliedFilter}
                                                    className="organization-workflows-screen__applied-filters__list__item"
                                                    label={`${t(`workflows.list.filters.${appliedFilter}`)}: ${getAppliedFilterDisplayValue(appliedFilter as keyof WorkflowsFilterParams)}`}
                                                    onDelete={() => removeFilter(appliedFilter as keyof WorkflowsFilterParams)}
                                                    deleteIcon={<RemoveIcon />}
                                                />
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="organization-workflows-screen__content">
                        <div className="organization-workflows-screen__content__large-filters" data-testid="filters-sidebar">
                            <WorkflowsFiltersForm
                                currentFilters={filters}
                                filterMemberOptions={filterOrganizationMembers}
                                filteredMemberOptions={memberOptions}
                                isLoadingMembers={isLoadingMembers}
                                onSubmit={applyFilters}
                                onClean={cleanAllFilters}
                            />
                        </div>
                        <div className="organization-workflows-screen__content__list">
                            <p className="BETA-migration-warning">{t('workflows.list.BETA_migrationWarning')}</p>
                            {!isLoading && workflows.length === 0 && (
                                <p className="organization-workflows-screen__content__list__empty-list">{t('workflows.list.noResults')}</p>
                            )}
                            {isLoading && <LoadingCircles size="m" variant="primary" />}

                            <InfiniteScrollWrapper
                                hasMore={!!filters._cursor}
                                requestMore={() => getWorkflowsList(filters)}
                            >
                                {workflows.map((workflow) => (
                                    <WorkflowCard key={workflow.id} workflow={workflow} />
                                ))}
                            </InfiniteScrollWrapper>

                        </div>
                        {canManage && (
                            <div className="add-btn-wrap">
                                <Link to={AppRoute.CreateWorkflowSelectType}>
                                    <Fab size="medium" color="primary" aria-label="add" data-testid="add-btn">
                                        <PlusIcon />
                                    </Fab>
                                </Link>
                            </div>
                        )}
                    </div>
                    <Drawer
                        open={isOpenFilters}
                        handleClose={() => setIsOpenFilters(false)}
                        title={t('general.filter')}
                        extraClasses="filters-drawer"
                        testId="filters-drawer"
                    >
                        <WorkflowsFiltersForm
                            currentFilters={filters}
                            filterMemberOptions={filterOrganizationMembers}
                            filteredMemberOptions={memberOptions}
                            isLoadingMembers={isLoadingMembers}
                            onSubmit={applyFilters}
                            onClean={cleanAllFilters}
                        />
                    </Drawer>
                </div>
            </DefaultLayout>
        </HasPermission>
    );
};

export const OrganizationWorkflowsScreen = withTranslationContext(withOrganizationsContext(withWorkflowContext(OrganizationWorkflowsScreenBase)));
