/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FormValidationError, getErrorsForField } from '../../utils/validations';
import { OptionSelectField } from '../../types/general';
import ErrorMessageBase from './ErrorMessage';
import { LoadingCircles } from './LoadingCircles';

type SelectAttributes = Partial<Pick<HTMLSelectElement, 'required'>>

type OwnProps<T> = SelectAttributes & {
    name: string;
    label?: string;
    onChange: (name: string, value: T) => void;
    prefix?: string;
    errors?: FormValidationError | null;
    options: OptionSelectField<T>[];
    value?: T;
    disabled?: boolean;
    disabledText?: string;
    extraClasses?: string;
    isLoading?: boolean;
}

/**
 * Unlockit custom selector.
 * To select this element during tests, target "select-<name>"
 *
 * @param props
 * @returns
 */
export const FormSelectField = <T, >(props: OwnProps<T>) => {
    const {
        options,
        name,
        onChange,
        prefix,
        errors,
        label,
        value,
        disabled,
        disabledText,
        extraClasses,
        isLoading = false,
        required,
    } = props;

    const fieldErrors = getErrorsForField(name, errors);
    const defaultOption = options.length > 0 ? options[0].value : null;

    return (
        <div className="form-field">
            <div className="form-field__top">
                {label && (
                    <label htmlFor={name} className={`form-field-label ${required && 'required'}`}>
                        {label}
                    </label>
                )}
            </div>
            <div className="form-field__container">
                {isLoading && (
                    <div className="input-loading-wrapper">
                        <LoadingCircles size="xs" variant="secondary" />
                    </div>
                )}
                {!isLoading && (
                    <div className={`form-field__container__input-wrapper ${fieldErrors ? 'has-error' : ''}`}>
                        {prefix && <span className="form-field__container__input-wrapper__prefix">{prefix}</span>}
                        <select
                            name={name}
                            defaultValue={value ? undefined : String(defaultOption)}
                            value={value ? String(value) : undefined}
                            onChange={(e) => onChange(name, e.target.value as unknown as T)}
                            data-testid={`select-${name}`}
                            disabled={disabled}
                            className={extraClasses}
                            required
                            aria-required={required}
                        >
                            {options.map((option) => (
                                <option value={String(option.value)} key={String(option.value)}>{option.label}</option>
                            ))}
                        </select>
                        {disabled && disabledText && (<span className="disable-warning">{disabledText}</span>)}
                        {fieldErrors ? <ErrorMessageBase errors={fieldErrors} field={name} /> : null}
                    </div>
                )}
            </div>
        </div>
    );
};
