/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
    TranslationContext,
    withTranslationContext,
} from '../../controllers/TranslationContext';

import { ContractState, Signer } from '../../../types/contracts';
import { ReactComponent as ProfileIcon } from '../../../assets/images/profile.svg';
import { ReactComponent as VerticalDotsIcon } from '../../../assets/images/vertical-dots.svg';
import Modal from '../Modal';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import { ButtonWithMenuList } from '../ButtonWithMenuList';
import { useContractContext } from './ContractContextProvider';
import { MenuListOptions } from '../../../types/general';
import { EditSignerDrawer } from './EditSignerDrawer';

interface OwnProps extends TranslationContext, ContractsContext {
    signer: Signer;
}

const SignerListItemComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        signer,
    } = props;

    const {
        contract,
        placeholderList,
        pendingPlaceholder,
        prepareToRemoveSigner,
        createPendingPlaceholder,
    } = useContractContext();

    const [openModalDeleteConfirm, setOpenModalDeleteConfirm] = useState(false);
    const [isEditingSigner, setIsEditingSigner] = useState(false);

    const hasPlaceholderInList: boolean = useMemo(() => pendingPlaceholder?.signer.id === signer.id || !!placeholderList.find((p) => p.signer.id === signer.id),
        [placeholderList, pendingPlaceholder]);

    const signerOptions = (): MenuListOptions[] => {
        const options: MenuListOptions[] = [];

        if (signer.editable) {
            options.push({
                value: t('signersOptions.EDIT'),
                action: () => setIsEditingSigner(true),
                testId: 'edit-btn',
            });
        }

        if (contract?.contractState === ContractState.DRAFT) {
            if (!hasPlaceholderInList) {
                options.push({
                    value: t('signersOptions.ADD_PLACEHOLDER'),
                    action: () => createPendingPlaceholder(signer),
                    testId: 'add-placeholder-btn',
                });
            }

            options.push({
                value: t('signersOptions.DELETE'),
                action: () => setOpenModalDeleteConfirm(true),
                testId: 'delete-btn',
            });
        }

        return options;
    };

    /**
     * Delete Signer
     *
     * @remarks
     * Initiates a DELETE request to remove this signer
     */
    const handleDeleteConfirm = async () => {
        await prepareToRemoveSigner(signer.id, onDeleteSuccess, onDeleteFailure);
    };

    /**
     * onSuccess calback function
     *
     * @remarks
     * Update state (and other tasks if needed) after sucessfull delete response
     */
    const onDeleteSuccess = () => {
        setOpenModalDeleteConfirm(false);
        toast.success(t('contractCreate.deleteSuccess'));
    };

    /**
     * onFailure calback function
     *
     * @remarks
     * Update state and show toast with generic failure message
     */
    const onDeleteFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    const renderSignerState = () => {
        if (!hasPlaceholderInList) {
            return (
                <span className="form__fields__signer-line__info__icon-wrap__no-placeholder">
                    {t('signerStates.doesNotHavePlaceholder')}
                </span>
            );
        }

        if (signer.signedAt) {
            return (
                <span className="form__fields__signer-line__info__icon-wrap__signed">
                    {t('signerStates.signed')}
                </span>
            );
        }

        return (
            <span className="form__fields__signer-line__info__icon-wrap__not-signed">
                {t('signerStates.notSigned')}
            </span>
        );
    };

    return (
        <>
            <div className="form__fields__signer-line" key={signer.id}>
                <div className="form__fields__signer-line__info">
                    <div className="form__fields__signer-line__info__icon-wrap">
                        <ProfileIcon />
                    </div>
                    <div className="form__fields__signer-line__info__icon-wrap__typography">
                        <p className="form__fields__signer-line__info__icon-wrap__name">{signer.name}</p>
                        {renderSignerState()}
                    </div>
                </div>
                <div className="form__fields__signer-line__actions">
                    <div className="form__fields__signer-line__actions__icon-wrap">
                        <ButtonWithMenuList
                            options={signerOptions()}
                            icon={<VerticalDotsIcon />}
                            data-testid="delete-btn"
                        />
                    </div>
                </div>
            </div>
            {/* DELETE MODAL */}
            <Modal
                open={openModalDeleteConfirm}
                title={t('contractCreate.deleteConfirmTitle')}
                handleClose={() => setOpenModalDeleteConfirm(false)}
                handleConfirm={handleDeleteConfirm}
            >
                <p>{t('contractCreate.deleteConfirmText')}</p>
            </Modal>
            <EditSignerDrawer
                isOpen={isEditingSigner}
                signerToEdit={signer}
                onClose={() => setIsEditingSigner(false)}
            />
        </>
    );
};

export const SignerListItem = withTranslationContext(withContractsContext(SignerListItemComponent));
