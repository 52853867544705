/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, {
    AriaAttributes,
    FunctionComponent,
    HTMLInputAutoCompleteAttribute,
    useEffect,
    useState,
} from 'react';
import { FormValidationError, getErrorsForField } from '../../utils/validations';
import ErrorMessageBase from './ErrorMessage';
import { FormFieldType } from '../../types/general';

type TextAreaBaseProps = Partial<Pick<HTMLTextAreaElement, 'rows' | 'cols' | 'minLength' | 'required' |'disabled'>>
type BaseInputAttributes = Partial<Pick<HTMLInputElement, 'required' | 'disabled'>>

type OwnProps = TextAreaBaseProps & BaseInputAttributes & {
    name: string;
    type?: FormFieldType;
    isTextArea?: boolean;
    label?: string;
    value?: string | number;
    onChange?: (name: string, value: string) => void;
    maxLength?: number;
    prefix?: string;
    placeholder?: string;
    errors?: FormValidationError | null;
    helperText?: string;
    extraClasses?: string;
    autoComplete?: HTMLInputAutoCompleteAttribute;
    ariaAutoComplete?: AriaAttributes['aria-autocomplete'];
}

/**
 * General HTML Form Field.
 * When testing, target the input value as "input-<name>"
 * @param props
 * @returns
 */
const FormField: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        label,
        type = FormFieldType.Text,
        name,
        value,
        onChange,
        maxLength,
        prefix,
        placeholder,
        errors,
        helperText,
        extraClasses,
        isTextArea = false,
        autoComplete,
        ariaAutoComplete,
        rows,
        cols,
        minLength,
        required,
        disabled = false,
    } = props;

    const fieldErrors = getErrorsForField(name, errors);
    const [inputValue, setInputValue] = useState<string | number | undefined>(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setInputValue(e.target.value);
        if (onChange) {
            onChange(name, e.target.value);
        }
    };

    const inputProps = {
        id: name,
        type,
        name,
        value: inputValue,
        onChange: handleOnChange,
        maxLength,
        'data-testid': `input-${name}`,
        placeholder,
        className: `${prefix ? 'input-prefixed' : ''} ${extraClasses}`,
        rows,
        cols,
        minLength,
        required,
        disabled,
    };

    return (
        <div className="form-field">
            <div className="form-field__top">
                {label ? <label htmlFor={name} className={`form-field-label ${required && 'required'}`}>{label}</label> : null}
            </div>
            <div className="form-field__container">
                <div className={`form-field__container__input-wrapper ${fieldErrors.length ? 'has-error' : ''}`}>
                    {prefix && <span className="form-field__container__input-wrapper__prefix">{prefix}</span>}
                    {isTextArea
                        ? <textarea {...inputProps} aria-multiline aria-required={required} />
                        : <input {...inputProps} autoComplete={autoComplete} aria-autocomplete={ariaAutoComplete} aria-required={required} />
                    }
                    <span className="form-field__container__helperText">
                        {helperText}
                    </span>
                    {fieldErrors.length ? <ErrorMessageBase errors={fieldErrors} field={name} /> : null}
                </div>
            </div>
        </div>
    );
};

export { FormField };
