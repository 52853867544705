/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { InnovatorsClubSection } from '../../elements/InnovatorsClubSection';
import { AppRoute } from '../../../constants/routes';
import { SubscriptionItemUsageCard } from '../../elements/billing/SubscriptionItemUsageCard';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { ErrorCode, ErrorResponse } from '../../../types/errors';
import { Subscription } from '../../../types/billing';
import { LoadingCircles } from '../../elements/LoadingCircles';
import Button from '../../elements/Button';
import { ButtonVariant } from '../../../types/general';
import { Alert } from '../../elements/Alert';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { formatToLocalDate } from '../../../utils/date';
import { preparePageTitle } from '../../../utils/route';
import { WalletUsageCard } from '../../elements/billing/WalletUsageCard';

type BaseProps = TranslationContext & BillingContext & OrganizationsContext;

export const DashboardScreenBase: FunctionComponent<BaseProps> = (props) => {
    const {
        t,
        organizationWallet,
        getSubscriptionDetails,
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [subscriptionUsage, setSubscriptionUsage] = useState<Subscription | null>(null);
    const [error, setError] = useState<null | ErrorResponse>(null);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = preparePageTitle(t('billingDashboard.title'));
        init();
    }, [organizationWallet]);

    useEffect(() => {
        if (error) throw error;
    }, [error]);

    const expirationDate = useMemo(() => {
        const currentDate = new Date();

        const endDate = subscriptionUsage?.endDate ? new Date(subscriptionUsage.endDate) : undefined;

        const oneMonthBeforeEndDate = endDate ? new Date(endDate) : undefined;
        if (oneMonthBeforeEndDate && endDate) {
            oneMonthBeforeEndDate.setMonth(endDate.getMonth() - 1);
        }

        const isCloseToExpiration = endDate && oneMonthBeforeEndDate
            ? currentDate >= oneMonthBeforeEndDate && currentDate <= endDate
            : false;

        const hasExpired = endDate ? endDate < currentDate : false;

        return {
            hasExpired,
            isCloseToExpiration,
        };
    }, [subscriptionUsage]);

    const init = () => {
        if (organizationWallet) {
            getSubscriptionUsage(organizationWallet.id);
        }
    };

    const getSubscriptionUsage = async (walletId: number) => {
        setIsLoading(true);
        const [requestedData, requestedDataError] = await getSubscriptionDetails(String(walletId));
        setIsLoading(false);

        if (requestedData) {
            setSubscriptionUsage(requestedData);
        }
        if (requestedDataError) {
            // There's no subscription usage if the error code is 404 not found
            if (String(requestedDataError.errors[0]?.errorCode) === ErrorCode.ENTITY_NOT_FOUND) {
                return;
            }
            setError(requestedDataError);
        }
    };
    return (
        <div className="subscription-dashboard-screen">
            <div className="subscription-dashboard-screen__header">
                <h1>{t('billingDashboard.title')}</h1>
            </div>
            {!isLoading && expirationDate.isCloseToExpiration && (
                <Alert
                    message={
                            t('billingDashboard.planIsCloseToExpiration', { date: formatToLocalDate(subscriptionUsage?.endDate) })

                        }
                    button={(
                        <Button
                            variant={ButtonVariant.Curved}
                            onClick={() => navigate(AppRoute.BillingPlans)}
                        >{t('billingDashboard.selectPlan')}
                        </Button>
                    )}
                />
            )}
            {!isLoading && expirationDate.hasExpired && (
                <Alert
                    message={t('billingDashboard.planExpired')}
                    button={(
                        <Button
                            variant={ButtonVariant.Curved}
                            onClick={() => navigate(AppRoute.BillingPlans)}
                        >{t('billingDashboard.renewSubscription')}
                        </Button>
                    )}
                />
            )}
            {!isLoading && (!subscriptionUsage || !organizationWallet) && (
                <Alert
                    message={t('billingDashboard.noActiveSubscription')}
                    button={(
                        <Button
                            variant={ButtonVariant.Curved}
                            onClick={() => navigate(AppRoute.BillingPlans)}
                        >{t('billingDashboard.selectPlan')}
                        </Button>
                    )}
                />
            )}
            <InnovatorsClubSection />
            {isLoading && <LoadingCircles size="m" variant="primary" />}
            {!isLoading && organizationWallet && (
                <div className="subscription-dashboard-screen__usage">
                    <Grid container spacing={2}>
                        {organizationWallet && (
                            <Grid item xs={12} md={6}>
                                <WalletUsageCard
                                    name="wallet"
                                    title={t('billingDashboard.wallet.title')}
                                    wallet={organizationWallet}
                                    addCreditsUrl={AppRoute.OrganizationPurchaseCredits}
                                />
                            </Grid>
                        )}
                        {subscriptionUsage?.items.map((i) => (
                            <Grid key={`subscription-item-${i.featureType}`} item xs={12} md={6}>
                                <SubscriptionItemUsageCard
                                    name={i.featureType}
                                    title={t(`billing.featureType.${i.featureType}`)}
                                    subscriptionItem={i}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            )}
        </div>
    );
};
export const DashboardScreen = withTranslationContext(withBillingContext(withOrganizationsContext(DashboardScreenBase)));
