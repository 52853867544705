/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { useAppSelector } from '../utils/storeHooks';
import { PermissionList } from '../types/permissions';

export const useUserHasPermission = (permissions: PermissionList) => {
    const currentPermissions = useAppSelector((state) => state.organization.currentPermissions);

    return permissions.some((permission) => currentPermissions?.includes(permission));
};
