/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
} from 'react';
import { UsageChart } from './UsageChart';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { PlanPricingType, SubscriptionItem } from '../../../types/billing';

type CommonProps = TranslationContext & {
    name?: string;
    title: string;
    subscriptionItem: SubscriptionItem;
}

type UnlimitedQuotaProps = CommonProps

type PayPerUseProps = CommonProps

type LimitedQuotaProps = CommonProps

type WrapperProps = CommonProps

const UnlimitedQuota: FunctionComponent<UnlimitedQuotaProps> = (props) => {
    const {
        t,
        name,
        title,
        subscriptionItem,
    } = props;

    return (
        <div className="usage-card" data-testid={`usage-card-${name}`}>
            <h2>{title}</h2>
            <div className="usage-card__info">
                <div className="usage-card__info__current-available" data-testid={`usage-card-${name}-current-available`}>
                    <span>{subscriptionItem.unitsUsed}</span>
                    <>{` ${t('billingDashboard.of')} `}</>
                    <span className="infini">&infin;</span>
                </div>
                <div className="usage-card__info__details">
                    <div className="usage-card__info__details__captive">
                        {subscriptionItem.unitsCaptive > 0 && `${t('billingDashboard.captive')}: ${subscriptionItem.unitsCaptive}`}
                    </div>
                </div>
            </div>
        </div>
    );
};

/**
 * Shows usage for a limited quota subscription item.
 * Renders 2 variants: quota exhausted or usage within quota
 *
 * @param props
 * @returns
 */
const LimitedQuota: FunctionComponent<LimitedQuotaProps> = (props) => {
    const {
        t,
        name,
        title,
        subscriptionItem,
    } = props;

    const isQuotaExhausted = subscriptionItem.unitsAvailable <= 0;

    return (
        <div className="usage-card" data-testid={`usage-card-${name}`}>
            <h2>{title}</h2>
            <div className="usage-card__info">
                <div className="usage-card__info__current-available" data-testid={`usage-card-${name}-current-available`}>
                    {isQuotaExhausted && subscriptionItem.unlockableWithCredits ? (
                        <>
                            <span>{subscriptionItem.unitPrice}</span>
                        &nbsp;{t('billingDashboard.usageCard.creditsPricePerUnit')}
                        </>
                    ) : (
                        <>
                            <span>{subscriptionItem.unitsAvailable}</span>
                            &nbsp;{`${t('billingDashboard.of')} ${subscriptionItem.quota}`}
                        </>
                    )}
                </div>
                <div className="usage-card__info__details">
                    <div className="usage-card__info__details__available">
                        {`${t('billingDashboard.available')}: ${subscriptionItem.unitsAvailable}`}
                    </div>
                    <div className="usage-card__info__details__captive">
                        {`${t('billingDashboard.captive')}: ${subscriptionItem.unitsCaptive}`}
                    </div>
                </div>
            </div>
            <UsageChart
                total={subscriptionItem.quota}
                available={subscriptionItem.unitsAvailable}
                captive={subscriptionItem.unitsCaptive}
            />
            {isQuotaExhausted && subscriptionItem.unlockableWithCredits && (
                <p>
                    {t('billingDashboard.usageCard.quotaExhausted.unlockableWithCredits')}
                </p>
            )}
            {isQuotaExhausted && !subscriptionItem.unlockableWithCredits && (
                <p>
                    {t('billingDashboard.usageCard.quotaExhausted.requiresUpgrade')}
                </p>
            )}
        </div>
    );
};

const PayPerUse: FunctionComponent<PayPerUseProps> = (props) => {
    const {
        t,
        name,
        title,
        subscriptionItem,
    } = props;

    return (
        <div className="usage-card" data-testid={`usage-card-${name}`}>
            <h2>{title}</h2>
            <div className="usage-card__info">
                <div className="usage-card__info__current-available" data-testid={`usage-card-${name}-current-available`}>
                    <span>{subscriptionItem.unitPrice}</span>
                    <>&nbsp;{t('billingDashboard.usageCard.creditsPricePerUnit')}</>
                </div>
                <div className="usage-card__info__details">
                    <div className="usage-card__info__details__used">
                        {`${t('billingDashboard.used')}: ${subscriptionItem.unitsUsed}`}
                    </div>
                    <div className="usage-card__info__details__captive">
                        {`${t('billingDashboard.captive')}: ${subscriptionItem.unitsCaptive}`}
                    </div>
                </div>
            </div>
        </div>
    );
};

const UsageCardWrapper: FunctionComponent<WrapperProps> = (props) => {
    const {
        subscriptionItem,
    } = props;

    switch (subscriptionItem.pricingType) {
        case PlanPricingType.UNLIMITED_QUOTA:
            return <UnlimitedQuota {...props} />;
        case PlanPricingType.LIMITED_QUOTA:
            return <LimitedQuota {...props} />;
        case PlanPricingType.PAY_PER_USE:
        default:
            return <PayPerUse {...props} />;
    }
};

export const SubscriptionItemUsageCard = withTranslationContext(UsageCardWrapper);
