/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ChangeEvent, FunctionComponent, useState } from 'react';
import { FormSelectField } from './FormSelectField';
import availableCurrencies from '../../assets/data/available-currencies.json';
import { OptionSelectField } from '../../types/general';
import { FormValidationError, getErrorsForField } from '../../utils/validations';
import ErrorMessageBase from './ErrorMessage';

type InputBaseAttributes = Partial<Pick<HTMLInputElement, 'required'>>

type OwnProps = InputBaseAttributes & {
    label?: string;
    name: string;
    testId?: string;
    currencySelected?: string;
    valueAmount?: number;
    errors?: FormValidationError | null;
    onValueAmountChange?: (numberValue: number) => void;
    onCurrencyChange?: (value: string) => void;
    extraClasses?: string;
}

const CurrencyInput: FunctionComponent<OwnProps> = (props) => {
    const {
        label,
        name,
        testId,
        currencySelected,
        valueAmount,
        errors,
        onValueAmountChange,
        onCurrencyChange,
        extraClasses,
        required,
    } = props;

    const [numberValue, setNumberValue] = useState<string>(valueAmount?.toString() || '');

    const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        const currentNumberValue = Number(value);
        
        setNumberValue(currentNumberValue.toString());
        if (onValueAmountChange) onValueAmountChange(currentNumberValue);
    };

    const getAvailableCurrencyOptions = (): OptionSelectField<string>[] => {
        return Object.keys(availableCurrencies).map((c) => ({
            value: c,
            label: c,
        }));
    };

    const fieldErrors = getErrorsForField(name, errors);
    
    return (
        <div className="currency-input" data-testid={testId ?? ''}>
            {label && <label htmlFor={name} className={`${required && 'required'}`}>{label}</label>}
            <div className={`currency-input-container ${extraClasses}`}>
                <input name={name} value={numberValue} onChange={handleValueChange} type="number" min="1" data-testid={`${testId}-number`} className={`${extraClasses}-number`} required aria-required={required} />
                <FormSelectField
                    options={getAvailableCurrencyOptions()}
                    onChange={(_, value) => { if (onCurrencyChange) onCurrencyChange(value); }}
                    name="currency-picker"
                    value={currencySelected}
                    extraClasses={`${extraClasses}-currency`}
                    required
                />
            </div>
            {fieldErrors.length ? <ErrorMessageBase errors={fieldErrors} field={name} /> : null}
        </div>
    );
};

export { CurrencyInput };
