
/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { uniq } from 'lodash';
import {
    FunctionComponent,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { ContractsFilterParams, ContractState, ContractType } from '../../../types/contracts';
import {
    ButtonVariant, ListResponse, OptionCheckboxGroupField, OrderOptions,
} from '../../../types/general';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../Button';
import { CheckboxFormGroup } from '../CheckboxFormGroup';
import { FormField } from '../FormField';
import { FormSelectField } from '../FormSelectField';
import { CheckboxGroupWithSearch } from '../CheckboxGroupWithSearch';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';

type OwnProps = TranslationContext & OrganizationsContext & {
    currentFilters: ContractsFilterParams;
    contractTypeOptionsData: ListResponse<ContractType>;
    loadContractTypes: () => Promise<void>;
    filterMemberOptions: (search?: string) => void;
    filteredMemberOptions: OptionCheckboxGroupField[];
    isLoadingMembers?: boolean;
    onSubmit: (filters: ContractsFilterParams) => void;
    onClean: () => void;
};

const ContractsFiltersFormBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        organizationSelected,
        currentFilters,
        contractTypeOptionsData,
        loadContractTypes,
        filterMemberOptions,
        filteredMemberOptions,
        isLoadingMembers,
        onSubmit,
        onClean,
    } = props;

    const [filters, setFilters] = useState<ContractsFilterParams>(currentFilters);

    const orderOptions = [
        { value: OrderOptions.ASCENDING, label: t('contracts.list.filters.orderAscending') },
        { value: OrderOptions.DESCENDING, label: t('contracts.list.filters.orderDescending') },
    ];

    const contractStatusOptions: OptionCheckboxGroupField[] = useMemo(() => [
        { id: ContractState.DRAFT, value: !!currentFilters.contractStatus?.includes(ContractState.DRAFT), label: t('contracts.list.filters.contractStatusOptions.DRAFT') },
        { id: ContractState.SIGNING, value: !!currentFilters.contractStatus?.includes(ContractState.SIGNING), label: t('contracts.list.filters.contractStatusOptions.SIGNING') },
        { id: ContractState.SIGNED, value: !!currentFilters.contractStatus?.includes(ContractState.SIGNED), label: t('contracts.list.filters.contractStatusOptions.SIGNED') },
        { id: ContractState.VOIDED, value: !!currentFilters.contractStatus?.includes(ContractState.VOIDED), label: t('contracts.list.filters.contractStatusOptions.VOIDED') },
    ], [currentFilters]);

    const signatureOptions: OptionCheckboxGroupField[] = useMemo(() => [
        { id: 'pendingSignature', value: !!currentFilters.pendingSignature, label: t('contracts.list.filters.requiresMySignature') },
    ], [currentFilters]);

    const memberOptions = useMemo(() => {
        return filteredMemberOptions.map((option) => (
            {
                ...option,
                value: !!filters.ownerIds && filters.ownerIds.includes(String(option.id)),
            }
        ));
    },
    [filters.ownerIds, filteredMemberOptions]);

    const contractTypeOptions: OptionCheckboxGroupField[] = useMemo(() => {
        return contractTypeOptionsData.results.map((contractType) => ({
            id: contractType.id,
            value: !!currentFilters.contractTypeIds?.includes(String(contractType.id)),
            label: contractType.name,
        }));
    }, [currentFilters, contractTypeOptionsData]);

    useEffect(() => {
        setFilters({ ...currentFilters });
    }, [currentFilters]);

    /**
     * Update the filters field change
     *
     * @remarks
     * Verify the field changed and update in filters state
     */
    const onFieldsChange = (name: string, value: unknown) => {
        setFilters((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    /**
     * Update the filters contractStatus change
     *
     * @remarks
     * Verify the field changed and update in filters state
     */
    const onContractStatusChange = (id: ContractState, value: boolean) => {
        const currentContractStatuses = filters.contractStatus ?? [];

        const newContractStatuses = value
            ? uniq([...currentContractStatuses, id])
            : currentContractStatuses.filter((contractState) => contractState !== id);

        setFilters((prevFields) => ({
            ...prevFields,
            contractStatus: newContractStatuses,
        }));
    };

    /**
     * Update the filters contractTypes change
     *
     * @remarks
     * Verify the field changed and update in filters state
     */
    const onContractTypesChange = (id: number | string, value: boolean) => {
        const currentContractTypes = filters.contractTypeIds ?? [];

        const newContractTypes = value
            ? uniq([...currentContractTypes, String(id)])
            : currentContractTypes.filter((contractType) => contractType !== String(id));

        setFilters((prevFields) => ({
            ...prevFields,
            contractTypeIds: newContractTypes,
        }));
    };
    
    /**
     * Update checkbox group field
     *
     * @remarks
     * Verify the field changed and update in filters state
     */
    const onCheckboxGroupChange = (id: number | string, checked: boolean) => {
        let selectedMemberIds = filters.ownerIds ?? [];
        if (checked) {
            selectedMemberIds = [...selectedMemberIds, String(id)];
        } else {
            selectedMemberIds = selectedMemberIds.filter((item) => item !== String(id));
        }

        setFilters({ ...filters, ownerIds: selectedMemberIds });
    };

    return (
        <form className="filters-form" data-testid="filters-form">
            <div className="filters-form__filters">
                <div className="filters-form__filters__order" data-testid="form-order">
                    <FormSelectField
                        name="_sort"
                        label={t('contracts.list.filters.order')}
                        options={orderOptions}
                        value={filters._sort}
                        onChange={onFieldsChange}
                    />
                    <div className="separator" />
                </div>
                <FormField
                    name="externalId"
                    label={t('contracts.list.filters.externalId')}
                    placeholder={t('contracts.list.filters.externalIdPlaceholder')}
                    value={filters.externalId}
                    onChange={onFieldsChange}
                    extraClasses="bg-gray-filled filter-externalId"
                />
                <div className="separator" />
                <CheckboxFormGroup
                    label={t('contracts.list.filters.contractStatus')}
                    options={contractStatusOptions}
                    onChange={(id: string | number, value: boolean) => onContractStatusChange(id as ContractState, value)}
                />
                <div className="separator" />
                <CheckboxFormGroup
                    label={t('contracts.list.filters.signature')}
                    options={signatureOptions}
                    onChange={(id, value) => onFieldsChange(String(id), value || undefined)}
                />
                <div className="separator" />
                {organizationSelected?.organization?.id && (
                    <>
                        <CheckboxGroupWithSearch
                            name="ownerIds"
                            label={t('contracts.list.filters.ownerIds')}
                            searchPlaceholder={t('contracts.list.filters.ownerIdsSearchPlaceholder')}
                            options={memberOptions}
                            onChange={onCheckboxGroupChange}
                            onSearch={filterMemberOptions}
                            isLoading={isLoadingMembers}
                        />
                        <div className="separator" />
                    </>
                )}
                <CheckboxFormGroup
                    testId="contractTypes"
                    label={t('contracts.list.filters.contractTypes')}
                    options={contractTypeOptions}
                    onChange={onContractTypesChange}
                    hasMore={!!contractTypeOptionsData.cursor}
                    loadMore={() => loadContractTypes()}
                />
                <div className="separator" />
                <FormField
                    name="signerName"
                    label={t('contracts.list.filters.signer')}
                    placeholder={t('contracts.list.filters.signerName')}
                    value={filters.signerName}
                    onChange={onFieldsChange}
                    extraClasses="bg-gray-filled"
                />
            </div>
            <div className="filters-form__buttons">
                <Button
                    variant={ButtonVariant.Curved}
                    extraClasses="secondary clean-btn"
                    onClick={onClean}
                    testId="clean-btn"
                >
                    {t('contracts.list.filters.cleanAll')}
                </Button>
                <Button
                    variant={ButtonVariant.Curved}
                    onClick={() => onSubmit(filters)}
                    testId="submit-btn"
                >
                    {t('general.applyFilters')}
                </Button>
            </div>
        </form>
    );
};

export const ContractsFiltersForm = withTranslationContext(withOrganizationsContext(ContractsFiltersFormBase));
