/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CheckmarkIcon } from '../../../assets/images/checkmark-green.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/clock.svg';
import { ReactComponent as CrossIcon } from '../../../assets/images/cross-red.svg';
import { AppRoute } from '../../../constants/routes';
import { DocumentStatus, WorkflowDocument } from '../../../types/workflows';
import { buildUrl } from '../../../utils/navigation';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

interface OwnProps extends TranslationContext {
    document: WorkflowDocument;
}

const DocumentCardComponent: FunctionComponent<OwnProps> = (props) => {
    const { document, t } = props;

    const navigate = useNavigate();

    const renderStateIcon = () => {
        switch (document.documentStatus) {
            case DocumentStatus.PENDING:
            case DocumentStatus.AWAITING_APPROVAL:
                return <ClockIcon />;
            case DocumentStatus.APPROVED:
                return <CheckmarkIcon />;
            case DocumentStatus.REJECTED:
                return <CrossIcon />;
            default:
                return <></>;
        }
    };

    const renderTextStatus = () => {
        return t(`workflows.status.${document.documentStatus}`, { name: document.lastUserInteractionName });
    };

    return (
        <div
            className="document-card"
            data-testid={`document-card-${document.id}`}
            onClick={() => navigate(buildUrl(AppRoute.ViewWorkflowDocument, { workflowId: String(document.transactionId), documentId: String(document.id) }))}
        >
            <div className="document-card__info">
                <span className={`document-card__info__icon-wrap document-card__info__icon-wrap--${document.documentStatus}`}>
                    {renderStateIcon()}
                </span>
                <div className="document-card__info__details">
                    <h1 title={document.documentType}>{document.documentTypeCode === 'OTHER' ? `${document.name} - ` : ''}{t(`workflowDocumentTypes.${document.documentTypeCode}`)}</h1>
                    <p className={`document-card__info__details__status document-card__info__details__status--${document.documentStatus}`}>
                        {renderTextStatus()}
                    </p>
                    {document.documentNotes && (
                        <p className="document-card__info__details__notes">
                            {t('workflows.documents.notes')}: {document.documentNotes.length > 100 ? `${document.documentNotes.slice(0, 100)}...` : document.documentNotes}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export const DocumentCard = withTranslationContext(DocumentCardComponent);
