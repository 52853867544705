/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    Fragment, FunctionComponent, useMemo, useState,
} from 'react';
import SignatureAvailabilities from '../../assets/data/signature-availability.json';
import {
    AvailableSignatureType, SignatureAvailabilityType,
    SignatureType,
} from '../../types/contracts';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { FormField } from './FormField';
import { FormSelectField } from './FormSelectField';

type BaseProps = TranslationContext;

const SignatureAvailabilityListBase: FunctionComponent<BaseProps> = ({ t }) => {
    const typedSignatureAvailabilities = SignatureAvailabilities as SignatureAvailabilityType;
    const [selectedSignatureType, setSelectedSignatureType] = useState<SignatureType | ''>('');
    const [searchCountry, setSearchCountry] = useState<string>('');

    const filteredList: AvailableSignatureType[] = useMemo(() => {
        const auxFilteredList: AvailableSignatureType[] = [];

        for (let i = 0; i < Object.keys(typedSignatureAvailabilities).length; i++) {
            const signatureType = Object.keys(typedSignatureAvailabilities)[i] as SignatureType;
            const signatureCountries = Object.values(typedSignatureAvailabilities)[i];

            // if the signature type selected is different from the current iteration, there's no need to try and add anything to the array
            // eslint-disable-next-line no-continue
            if (selectedSignatureType !== '' && selectedSignatureType !== signatureType) continue;

            signatureCountries
                .filter((c) => c.name.toLowerCase().includes(searchCountry.toLowerCase()))
                .forEach((signatureCountry) => {
                    const {
                        flag, alpha3, name: countryName, documents,
                    } = signatureCountry;

                    // check if the country is already in the list
                    const availableSignatureType = auxFilteredList.find((v) => v.alpha3 === signatureCountry.alpha3);

                    // if the country is not in the list, add it and add the signature type
                    if (!availableSignatureType) {
                        auxFilteredList.push({
                            countryName,
                            flag,
                            alpha3,
                            signature: [
                                {
                                    signatureType,
                                    documents,
                                },
                            ],
                        });
                    } else { // if the country is already in the list, just add the signature type and its documents
                        availableSignatureType.signature.push({
                            signatureType,
                            documents,
                        });
                    }
                });
        }

        return auxFilteredList.sort((a, b) => a.countryName.localeCompare(b.countryName));
    }, [selectedSignatureType, searchCountry]);

    /**
     * Map Signature Types
     *
     * @remarks
     * Map Signature Types for label/value needed for the select
     */
    const getMappedSignatureKeys = useMemo(() => {
        const signatureOptions = [{ value: '', label: t('signatureAvailability.allSignatureTypes') }];
        Object.entries(typedSignatureAvailabilities)
            .forEach(([signatureKey]) => {
                signatureOptions.push({
                    value: signatureKey,
                    label: t(`signatureAvailability.${signatureKey}`),
                });
            });

        return signatureOptions;
    }, []);

    return (
        <div className="signature-availability__content">
            <FormField
                name="country-filter"
                label={t('signatureAvailability.filterByCountryName')}
                placeholder={t('signatureAvailability.filterByCountryNamePlaceholder')}
                value={searchCountry}
                onChange={(_, v) => setSearchCountry(v)}
            />
            <FormSelectField
                name="signature-type"
                label={t('signatureAvailability.filterBySignatureType')}
                onChange={(_, v) => setSelectedSignatureType(v as SignatureType)}
                options={getMappedSignatureKeys}
                value={selectedSignatureType}
            />

            <div className="signature-availability__content__item">
                <ul data-testid="countries-list">
                    {filteredList.map((signatureByCountry) => (
                        <li key={signatureByCountry.alpha3}>
                            <div className="signature-availability__content__item__country">
                                <div className="signature-availability__content__item__country__flag"> {signatureByCountry.flag}</div>
                                <h4>{signatureByCountry.countryName}</h4>
                            </div>
                            {signatureByCountry.signature.map((signature) => (
                                <Fragment key={`${signatureByCountry.alpha3}-${signature.signatureType}`}>
                                    <p>{t(`signatureAvailability.${signature.signatureType}`)}</p>
                                    <ul>
                                        {signature.documents.map((document) => (
                                            <li key={`${signatureByCountry.alpha3}-${signature.signatureType}-${document.name}${document.year ? `-${document.year}` : ''}`}>
                                                {t(`signatureAvailability.${document.i18n}`)} {document.year ? `(${document.year})` : ''}
                                            </li>
                                        ))}
                                    </ul>
                                </Fragment>
                            ))}
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    );
};

export const SignatureAvailabilityList = withTranslationContext(SignatureAvailabilityListBase);
