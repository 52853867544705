/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, {
    FunctionComponent, useEffect, useRef, useState,
} from 'react';
import { LoadingCircles } from './LoadingCircles';

interface OwnProps {
    children: React.ReactNode;
    hasMore: boolean;
    requestMore(): Promise<void>;
    extraClasses?: string;
}

const InfiniteScrollWrapper: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        children,
        hasMore,
        requestMore,
        extraClasses,
    } = props;

    const [loading, setLoading] = useState(false);
    const infiniteLoaderElementRef = useRef<HTMLDivElement | null>(null);

    const handleScroll = () => {
        if (loading || !hasMore) return;

        const { clientHeight, scrollHeight, scrollTop } = document.documentElement;
        const bottom = scrollHeight - (scrollTop + clientHeight);

        if (bottom <= 100) {
            setLoading(true);
            requestMore()
                .catch()
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        infiniteLoaderElementRef.current?.addEventListener('scroll', handleScroll);

        return () => {
            infiniteLoaderElementRef.current?.removeEventListener('scroll', handleScroll);
        };
    }, [loading, hasMore, requestMore]);

    return (
        <div
            className={`infinite-scroll-wrapper ${extraClasses}`}
            onScroll={handleScroll}
            ref={infiniteLoaderElementRef}
            data-testid="infinite-scroll-wrapper"
        >
            {children}
            {loading && (
                <LoadingCircles size="xs" variant="primary" />
            )}
        </div>
    );
};

export default InfiniteScrollWrapper;
